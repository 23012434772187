import React  from 'react';
import './roadmap.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { GiWorld } from 'react-icons/gi';
import { ImBlog } from 'react-icons/im';
import { FaRegPaperPlane } from 'react-icons/fa';
import { MdOutlineGeneratingTokens } from 'react-icons/md';
import { TiNews } from 'react-icons/ti';
import { GiFreedomDove } from 'react-icons/gi';

const Roadmap = () => {

  return (
    <section className='container container__roadmap' id='roadmap'>
        <div className='roadmap'>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'var(--color-bgtext)', color: 'var(--color-main)' }}
                    contentArrowStyle={{ borderRight: '7px solid  var(--color-bgtext)' }}
                    date="2023"
                    iconStyle={{ background: 'var(--color-bgtext-nt)', color: 'var(--color-main)' }}
                    icon={<GiWorld />}
                >
                    <h3 className="vertical-timeline-element-title" style={{ color: 'var(--color-main)'}}>Build the Community</h3>
                    <h4 className="vertical-timeline-element-subtitle">Twitter is the first step to exchange ideas.</h4>
                    <p>Fun and respect are mandatory 😎</p>
                </VerticalTimelineElement>
    
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'var(--color-bgtext)', color: 'var(--color-main)' }}
                    contentArrowStyle={{ borderRight: '7px solid  var(--color-bgtext)' }}
                    date="2023"
                    iconStyle={{ background: 'var(--color-bgtext-nt)', color: 'var(--color-main)' }}
                    icon={<FaRegPaperPlane />}
                >
                    <h3 className="vertical-timeline-element-title" style={{ color: 'var(--color-main)'}}>Open to submissions</h3>
                    <h4 className="vertical-timeline-element-subtitle">Whistleblowers and critical thinkers share their stories.</h4>
                    <p>Skiff encrypted mail is used to communicate.</p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'var(--color-bgtext)', color: 'var(--color-main)' }}
                    contentArrowStyle={{ borderRight: '7px solid  var(--color-bgtext)' }}
                    date="2023"
                    iconStyle={{ background: 'var(--color-bgtext-nt)', color: 'var(--color-main)' }}
                    icon={<ImBlog />}
                >
                    <h3 className="vertical-timeline-element-title" style={{ color: 'var(--color-main)'}}>Blog Dapp</h3>
                    <h4 className="vertical-timeline-element-subtitle">Critical texts and invisible stories from whistleblowers are released on this platform.</h4>
                    <p></p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'var(--color-bgtext)', color: 'var(--color-main)' }}
                    contentArrowStyle={{ borderRight: '7px solid  var(--color-bgtext)' }}
                    date="2023"
                    iconStyle={{ background: 'var(--color-bgtext-nt)', color: 'var(--color-main)' }}
                    icon={<MdOutlineGeneratingTokens />}
                >
                    <h3 className="vertical-timeline-element-title" style={{ color: 'var(--color-main)'}}>Governance Token</h3>
                    <h4 className="vertical-timeline-element-subtitle">The publishing of news stories is democratized.</h4>
                    <p>Strategy to ensure the authenticity of news stories by citizen journalists.</p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'var(--color-bgtext)', color: 'var(--color-main)' }}
                    contentArrowStyle={{ borderRight: '7px solid  var(--color-bgtext)' }}
                    date="2024-2025"
                    iconStyle={{ background: 'var(--color-bgtext-nt)', color: 'var(--color-main)' }}
                    icon={<TiNews />}
                >
                    <h3 className="vertical-timeline-element-title" style={{ color: 'var(--color-main)'}}>News Stories Dapp</h3>
                    <h4 className="vertical-timeline-element-subtitle">Enhancement of the Blog Dapp - worldwide news stories take place on this plateform.</h4>
                    <p>Strategy to bypass mainstream fake news.</p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'var(--color-bgtext)', color: 'var(--color-main)' }}
                    contentArrowStyle={{ borderRight: '7px solid  var(--color-bgtext)' }}
                    date="2025-2026"
                    iconStyle={{ background: 'var(--color-bgtext-nt)', color: 'var(--color-main)' }}
                    icon={<GiFreedomDove />}
                >
                    <h3 className="vertical-timeline-element-title" style={{ color: 'var(--color-main)'}}>Metaverse</h3>
                    <h4 className="vertical-timeline-element-subtitle">Build a broadcast studio.</h4>
                    <p>Access to worldwide news stories and critical discussions.</p>
                </VerticalTimelineElement>   
            </VerticalTimeline>
        </div>
    </section>
  )
}

export default Roadmap