import React from 'react';
import './mission.css';


const Mission = () => {

    return (
        <section className='container container__mission' id='mission'>
            <div className='mission__text'>
                <p>Amidst the battle for Truth, standing tall against the propaganda machine's thrall, a vision takes shape. 
                In a world where an endless stream of information muddles the way, and freedom is disdained, 
                CTWS pledges to unveil hidden and suppressed realities, telling tales that enlighten and educate, in a mission to inspire and encourage critical thinking and intellectual freedom.</p>
                <p>CTWS promotes transparency in storytelling, embracing facts and opinions as essential elements for discussing and understanding an objective reality. Privacy and freedom form the cornerstone of the intellectual ethics, for in a world where Truth is a rarity, sometimes anonymity offers much-needed security. Yet, self-identification is not forbidden, for authenticity is the shining star that guides this mission.</p>
                <p>In addition to the realm of critical texts and citizen journalism, where independent perspectives and diverse voices intertwine, 
                    CTWS beckons tales of all kinds, from witty satire and ironic jibes to tales of conspiracy and survival, even life experiences that mirror our own, its allure knows no bounds. 
                    In a world where censorship reigns supreme, these stories become catalysts, poised to shatter the chains of silence, encouraging us to break free and unshackle our minds.</p>
            </div>
        </section>
    )
  }
  
  export default Mission