import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { links } from './data.js';
import './nav.css';
import LOGO from '../../assets/logo.png';
import Twitter from '../../assets/twitter.svg';
import Opensea from '../../assets/opensea.svg';
import { CgMenuCake } from 'react-icons/cg';
import { IoMdCloseCircleOutline } from 'react-icons/io';


const Nav = () => {

  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav>
      <div className='container nav__container'>
        <Link to='/' className='logo'>
          <img src={LOGO} className='nav__logo' alt='logo' />
        </Link>
        
        <ul className={`nav__links ${isOpen ? 'show__nav' : 'hide__nav'}`}>
          {
            links.map(({name, path}, index) => {
              return (
                <li key={index}>
                  <NavLink to={path} className={({isActive}) => isActive ? 'active-nav' : '' } onClick={handleLinkClick}>{name}</NavLink>
                </li>
              )
            })
          }
          </ul>

          <ul className='nav__socials'>
            <li><a href='https://twitter.com/charles_seawell' target='_blank' rel='noreferrer'>
              <img src={Twitter} className='nav__socials' alt='twitter'/></a></li>    
            <li><a href='https://opensea.io/collection/ctw-stories' target='_blank' rel='noreferrer'>
              <img src={Opensea} className='nav__socials' alt='opensea' /></a></li>
          </ul>

          <button className='nav__toggle-btn' onClick={() => setIsOpen(!isOpen)}>
                {
                  isOpen ? <IoMdCloseCircleOutline /> : <CgMenuCake />
                }
          </button>

      </div>
    </nav>
  )
  }

export default Nav