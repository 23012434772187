import React, { useCallback } from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import particlesOptions from "./particles.json";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Nav from './components/nav/Nav';
import Header from './components/header/Header';
import Mission from './components/mission/Mission';
import Roadmap from './components/roadmap/Roadmap';
import Team from './components/team/Team';
import Faq from './components/faq/Faq';
import Footer from './components/footer/Footer';

function App() {

    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    return (
        <>
        <Particles options={particlesOptions} init={particlesInit}/>
        <BrowserRouter>
            <Nav />
            <Routes>
                <Route path="/" element={<Header/>}/>
                <Route path="/mission" element={<Mission/>}/>
                <Route path="/roadmap" element={<Roadmap/>}/>
                <Route path="/team" element={<Team/>}/>
                <Route path="/faq" element={<Faq/>}/>
            </Routes>
            <Footer />
        </BrowserRouter>
        </>
    )
}

export default App;
