import React from 'react';
import './header.css';
import Video from '../../assets/Woman.mp4';


const Header = () => {

  return (
      <section className='container header__container' id='art'>
        <video 
        src={Video}
        alt="silence"
        autoPlay
        muted
        loop
        playsInline
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          pointerEvents: 'none',
          opacity: 0.4,
          zIndex: 1,
        }} />
      
        <div className='header__text' >
          <div className='header__title'>
            <h1>Criminal Thinker and Whistleblower Stories</h1>
            <svg>
                <filter id="fire">
                  <feTurbulence id="turbulence" baseFrequency="0.1 0.1" numOctaves="2" seed="3">
                    <animate attributeName="baseFrequency" dur="30s" values="0.1 0.1; 0.12 0.2" repeatCount="indefinite"></animate>
                  </feTurbulence> 
                  <feDisplacementMap in="SourceGraphic" scale="7"></feDisplacementMap>
                </filter>
            </svg>
            </div>
            <div className='header__p'>
              <p>Having dwelled in the shadows for years, it's time to step into the light and break the silence. CTWS is an assembly of 
              authentic human beings who embraces the ideal of unbridled thinking and discourse.</p>
              <p>Gaze upon a masterful assemblage of 8222 ERC-721 NFTs, crafted with utmost care and grace, now within reach on Polygon's embrace. 
              These NFTs bear a noble cause - to foster a decentralized news network where Truth and critical thinking reign with poise.</p>
            </div>
        </div>
      </section>
  )
}

export default Header