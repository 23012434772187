import React from 'react';
import './footer.css';

const Footer = () => {
    return (
       
        <section className='container container__footer'>

            <footer id="footer">
            {/*<img src={LOGO} className='footer__logo' alt='logo'/>&#169; CTW Stories 2023 | All rights reserved</footer>*/}
            &#169; CTW Stories 2023 | All Rights Reserved</footer>
            
        </section>
    )
  };
  
  export default Footer