import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineMinus } from 'react-icons/ai';

const Plus = ({ question, answer, index, openIndex, setOpenIndex }) => {
  const isOpen = index === openIndex;

  const handleClick = () => {
    setOpenIndex(isOpen ? null : index);
  };

  return (
    <article className="faq" onClick={handleClick}>
      <div>
        <h3>{question}</h3>
        <button className="faq__icon">{isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />}</button>
      </div>
      {isOpen && <p>{answer}</p>}
    </article>
  );
};

export default Plus;
