import React  from 'react';
import './team.css';
import IMG1 from '../../assets/7249.png';

const Team = () => {

  return (
    <section className='container container__team' id='team'>
        <div className='wrapper'>
            <div className='card'>
                <img src={IMG1} alt='team-1' />
                <div className='info'>
                    <h3>Dr. Charles Seawell</h3>
                    <p>Scholar-Activist</p>
                </div>
            </div>
            <div className='card-2'>
                <div className='info-2'>
                    <h3>Dr. Charles Seawell is a scholar-activist and wants to work with critical thinkers and web3 developers.</h3>
                </div>
            </div>
        </div>
        
    </section>
  )
}

export default Team