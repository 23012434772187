import { useState } from 'react';
import './faq.css';
import { faqs } from './data.js';
import Plus from './Plus';
import Video from '../../assets/Hacker.mp4';

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(3);

  return (
    <section className="container container__faq" id="faq">
      <video
        src={Video}
        alt="hack"
        autoPlay
        muted
        loop
        playsInline
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          pointerEvents: 'none',
          opacity: 0.4,
          zIndex: 1,
        }}
      />

      <div className="faqs">
        <div className="faqs__wrapper">
          {faqs.map(({ id, question, answer }, index) => (
            <Plus
              key={id}
              question={question}
              answer={answer}
              index={index}
              openIndex={openIndex}
              setOpenIndex={setOpenIndex}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
