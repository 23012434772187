export const links = [
    {
        name: 'Art',
        path: '/'
    },
    {
        name: 'Mission',
        path: '/mission'
    },
    {
        name: 'Roadmap',
        path: '/roadmap'
    },
    {
        name: 'Team',
        path: '/team'
    },
    {
        name: 'FAQ',
        path: '/faq'
    }
]