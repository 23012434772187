export const faqs = [
{
    id: 1,
    question: 'When will we launch?',
    answer: 'The selling takes place on Opensea and the collection is already revealed on Polygon!' 
},
{
    id: 2,
    question: 'What is the supply?',
    answer: 'A total of 8222 unique NFTs are available.'
},
{
    id: 3,
    question: 'How much it cost?',
    answer: 'The floor cost for every NFT is 0.04 ETH + gas fee.'
    },
{
    id: 4,
    question: 'What benefits will you receive?',
    answer: "You will support free speech and make visible thoughts and stories that would have otherwise stayed in the darkness. In doing so, you will promote critical thinking by supporting different perspectives and ideas. Ultimately, you might contribute to changing some lives in the long term by allowing people to tell their stories and challenging the status quo. When you log in with your NFT, you will gain complimentary access to the platform's content!"
    },
]
